import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Contact from '../containers/Contact';

const propTypes = {
  data: PropTypes.shape({
    contentfulCompanyQuote: PropTypes.shape({
      title: PropTypes.string,
      quote: PropTypes.string,
      footerDescription: PropTypes.shape({}).isRequired,
      icons: PropTypes.arrayOf({
        fluid: PropTypes.shape({}),
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

const ContactPage = ({ data }) => {
  return <Contact companyQuote={data.contentfulCompanyQuote} />;
};

ContactPage.propTypes = propTypes;
export default ContactPage;

export const query = graphql`
  query {
    contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      title
      quote
      footerDescription {
        json
      }
      icons {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;
